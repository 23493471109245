//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "mostrarStockModal",
  props: ["model", "productoObj"],
  data() {
    return {
      columnasStock: [
        {label: "Almacén", field: "titulo", headerClasses: "w80p", align: "left"},
        {
          label: "Stock",
          field: "stock",
          headerClasses: "w20p",
          align: "center",
          format: val => this.productoObj.tipoProducto === 104 ? Math.floor(val / this.productoObj.cantidadEquivalente) : val
        },
      ],
    }
  },
  computed: {
    totalStockProducto() {
      return _.sumBy(this.productoObj.stockAlmacenes, s => Number(s.stock));
    }
  },
  methods: {
    onClose() {
      this.model = false;
      this.$emit('on-close');
    },
    getTipoProductoNombre(tipoProducto) {
      if (!tipoProducto) return "--";

      switch (tipoProducto) {
        case 101:
          return "Producto";
        case 102:
          return "Servicio";
        case 103:
          return "kit";
        case 104:
          return "Alias";
      }
    }
  }
}
